
@font-face {font-family: "Edwardian Script ITC V1";
  src: url("../src/assets/ca6f91c0cad2fe33614026d17117601d.eot"); /* IE9*/
  src: url("../src/assets/ca6f91c0cad2fe33614026d17117601d.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../src/assets/ca6f91c0cad2fe33614026d17117601d.woff2") format("woff2"), /* chrome、firefox */
  url("../src/assets/ca6f91c0cad2fe33614026d17117601d.woff") format("woff"), /* chrome、firefox */
  url("../src/assets/ca6f91c0cad2fe33614026d17117601d.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../src/assets/ca6f91c0cad2fe33614026d17117601d.svg#Edwardian Script ITC V1") format("svg"); /* iOS 4.1- */
}

td {
  max-width: 60px;
}

.App-header {
  width: 100%;

}

a {
  word-break: break-word;
}

p {
  font-family: sans-serif;
}

.col {
  font-family: sans-serif;
}
.App-link {
  color: #000000;
}

.logoleft {
  float: left;
}

.h2, h2 {
  font-size: 60px;
}

.h5, .h6 {
  font-family: sans-serif;
}

.title {
  margin: auto;
}

.row {
  margin: 15px 0px !important;
  width: 100% !important;
}

.navbar-light .navbar-toggler {
  color: rgba(234, 0, 0, 0.89)!important;
  border-color: rgb(10, 10, 10)!important;
}

.nav {
  float: right;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 0px;
  background: #ffffffc4 !important;
  border-radius: 5px;
  min-width: 76px;
  padding: 7px 30px;
  text-align: center;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 2px;
}

.navlink {
  margin-right: 0px;
  background: #ffffffc4 !important;
  border-radius: 5px;
  min-width: 76px;
  padding: 7px 30px;
  text-align: center;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 2px;
}

  .logotopright {
    position: absolute;
    right: 5px;
    top: -132px;
    width: 125px;
    border-radius: 60px;
  }
  .logolarge {
    max-height: 20px
  }

  .headerTitle h2 {
    color: #000000;
    margin-top: 10px;
    font-size: 86px;
}

a:visited {
  color: #000000;
}

a {
  color: #000000 !important;
}


/*************** HOME *****************/

.homemain {
  background: magenta;
}

.pagebg {
  background: #ffffffc4;
  margin: 25px;
  border-radius: 20px;
  color: #000000;
}

.contentTop {
  min-height: 25px;
  padding: 0px;
  margin: 0px !important;
}

.contentUpperMiddle {
  min-height: 25px;
  margin: 0px !important;
}

.contentLowerMiddlePhoto{
  margin: 25px;
}

.contentUpperMiddlePhoto{
  margin: 25px;
}

.contentLowerMiddleText {
  font-family: sans-serif;
  /* font-size: larger; */
  margin: 16px;
}

.contentUpperMiddleText {
  font-family: sans-serif;
  /* font-size: larger; */
  margin: 16px;
}

.contentLowerMiddle {
  min-height: 25px;
  margin: 0px !important;
}

.contentButtom {
  min-height: 25px;
  margin: 0px !important;
}

.heading {
  text-align: center;
}

.topSpacer {
  background: #ffffffc4;
  margin-top: 50px;
  padding-bottom: 20px;
}

/*************** About ****************/

.AboutMain {
  color: #000000;
  position: relative;
  text-align: center;
  width: 100%;
  min-height: 50px;
  }

  .AboutMain > h2 {
    position: absolute;
    top: 0%;
    left: 45%;
    color: #000000;
    font-size: 40px;
    font-family:"Edwardian Script ITC V1" !important;
    text-shadow: 2px 2px #f8f9fa6b ;
  }

  .ShopMain {
    color: #000000;
    position: relative;
    text-align: center;
    width: 100%;
    min-height: 50px;
    }
  
    .ShopMain > h2 {
      position: absolute;
      top: 0%;
      left: 42%;
      color: #000000;
      font-size: 40px;
      font-family:"Edwardian Script ITC V1" !important;
      text-shadow: 2px 2px #f8f9fa6b ;
    }

  .fittedsaddleMain {
    color: #000000;
    position: relative;
    text-align: center;
    width: 100%;
    min-height: 50px;
    }
  
    .fittedsaddleMain > h2 {
      position: absolute;
      top: 45%;
      left: 40%;
      color: #000000;
      font-size: 40px;
      font-family:"Edwardian Script ITC V1" !important;
      text-shadow: 2px 2px #f8f9fa6b ;
    }

    .otherServicesMain {
      color: #000000;
      position: relative;
      text-align: center;
      width: 100%;
      min-height: 50px;
      }
    
      .otherServicesMain > h2 {
        position: absolute;
        top: 45%;
        left: 40%;
        color: #000000;
        font-size: 40px;
        font-family:"Edwardian Script ITC V1" !important;
        text-shadow: 2px 2px #f8f9fa6b ;
      }

.AboutImage{
  width:100%;
}
.infoImage {
  width:100% !important;
}



/*************** Header ****************/

  .headerTitle {
    color: #000000;
    position: relative;
    text-align: center;
  }
  
  .headerTitle h2 {
    /* position: absolute;
    top: 45%;
    left: 45%;
    color: #000000;*/
    font-size: 80px; 
    font-family:"Edwardian Script ITC V1" !important;
    text-shadow: 2px 2px #f8f9fa6b ;
  }


/* .headerTitle h2 {
  position: absolute;
  top: 45%;
  left: 25%;
  color: #000000;
  font-size: 40px;
  font-family: URW Chancery L, Apple Chancery, cursive;
  text-shadow: 2px 2px #f8f9fa6b ;
} */


/************** Signin ***************/
.signinForm {
  margin: 25%;
  color: #000000;
}


/************** images ****************/
.uploadForm {
  max-width: 175px;
  color: #000000;
}

.cardLabel {
  color: gray;
  font-size: 10px;
  font-family: sans-serif;
}


@media only screen and (min-width: 992px) {
  .logotopright {
    display: none;
    
  }

  .titlebackground {
    background: #f8f9fa;
    position: absolute;
    filter: blur(75px);
    width: 63%;
    left: 21%;
    top: 43px;
    height: 216px;
  }

  .titlebackgroundText {
    background: #f8f9fa;
    position: absolute;
    filter: blur(800px);
    width: 100%;
    left: 0%;
    top: 0;
    height: 100%;
  }

  .logoright {
    right: 0;
    border-radius: 105px;
    width: 253px;
    /* top: 111px; */
    position: absolute;
  }

  .logodevice {
    display: none;
  }
  .logolarge {
    /* position: absolute;
    right: 35px;
    top: -95px;
    width: 81px; */
    border-radius: 40px;
  }

  .logo img {
    border-radius: 130px;
    max-width: 250px;
    margin: 15px 0 0 20px;
  }
/*
  .textBlur {
     position: absolute 
  }*/
}

@media only screen and (min-width: 991px) and (min-width: 1200px) {
  
  .container {
    min-width: 80%;
    
  }
  .logodevice {
    display: none;
  }
}

 @media only screen and (min-width: 767px) and (max-width: 991px) {
 .logotopright {
    position: absolute;
    right: -357px;
    top: 12px;
    width: 125px;
    border-radius: 60px;
  }

  .topSpacer {
    background: #ffffffc4;
    min-height: 500px;
    margin-top: 175px;
}
.headerTitle {
  color: #ffffff;
  /* vertical-align: 50%; */
  position: relative;
  margin-top: 50px;
  text-align: center;
}

.headerTitle h2 {
  color: #000000;
  font-size: 40px;
  font-weight: bolder;
}

.AboutMain > h2 {
  position: absolute;
  top: -52px;
  left: 35%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.ShopMain > h2 {
  position: absolute;
  top: -40px;
  left: 35%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.fittedsaddleMain >  h2 {
  position: absolute;
  top: -35px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.otherServicesMain >  h2 {
  position: absolute;
  top: -35px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.pagebg {
  background: #ffffffc4;
  margin: 30px 0 25px;
  border-radius: 20px;
  color: #000000;
}
  .logolarge {
    display: none;
  }

  .logo img {
    border-radius: 130px;
    max-width: 120px;
    margin: 15px 0 0 20px;
  }

  .titlebackground {
    background: #f8f9fa;
    position: absolute;
    filter: blur(50px);
    width: 100%;
    height: 160px;
  }

  .titlebackgroundText {
    background: #f8f9fa;
    position: absolute;
    filter: blur(120px);
    width: 100%;
    left: 0%;
    top: 0;
    height: 100%;
    display: none;
  }
  .AboutImage{
    width:100%;
  } 
  .infoImage {
    width:100% !important;
  }

  .contentUpperMiddlePhoto {
    margin: 0px;
  }

  .contentUpperMiddlePhoto :first-child {
    width: 100%;
  }

  .contentLowerMiddlePhoto {
    margin: 0px;
  }
  .card {
    min-width: 250px !important;
  }
}

@media only screen and (max-width: 576px) {
  .topSpacer {
    background: #ffffffc4;
    min-height: 500px;
    margin-top: 175px;
}
.headerTitle {
  color: #ffffff;
  /* vertical-align: 50%; */
  position: relative;
  margin-top: 50px;
  text-align: center;
}

.card {
  min-width: 100%;
}

.headerTitle h2 {
  color: #000000;
  font-size: 40px;
  font-weight: bolder;
}

.AboutMain > h2 {
  position: absolute;
  top: -20px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.ShopMain > h2 {
  position: absolute;
  top: -20px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.fittedsaddleMain >  h2 {
  position: absolute;
  top: -20px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.otherServicesMain >  h2 {
  position: absolute;
  top: -20px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.pagebg {
  background: #ffffffc4;
  margin: 30px 0 25px;
  border-radius: 20px;
  color: #000000;
}
  .logolarge {
    display: none;
  }

  .logo img {
    border-radius: 130px;
    max-width: 120px;
    margin: 15px 0 0 20px;
  }

  .titlebackground {
    background: #f8f9fa;
    position: absolute;
    filter: blur(50px);
    width: 100%;
    height: 160px;
  }

  .titlebackgroundText {
    background: #f8f9fa;
    position: absolute;
    filter: blur(120px);
    width: 100%;
    left: 0%;
    top: 0;
    height: 100%;
    display: none;
  }
} 


@media only screen and (min-width: 577px) and (max-width: 766px) {

  .logotopright {
    position: absolute;
    right: -260px;
    top: 7px;
    width: 125px;
    border-radius: 60px;
  }

  .card {
    min-width: 250px !important;
  }

  .topSpacer {
    background: #ffffffc4;
    min-height: 500px;
    margin-top: 175px;
}
.headerTitle {
  color: #ffffff;
  /* vertical-align: 50%; */
  position: relative;
  margin-top: 50px;
  text-align: center;
}

.headerTitle h2 {
  color: #000000;
  font-size: 40px;
  font-weight: bolder;
}

.AboutMain > h2 {
  position: absolute;
  top: -30px;
  left: 35%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.ShopMain > h2 {
  position: absolute;
  top: -45px;
  left: 25%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.fittedsaddleMain >  h2 {
  position: absolute;
  top: -40px;
  left: 25%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.otherServicesMain >  h2 {
  position: absolute;
  top: -40px;
  left: 25%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.pagebg {
  background: #ffffffc4;
  margin: 30px 0 25px;
  border-radius: 20px;
  color: #000000;
}
  .logolarge {
    display: none;
  }

  .logo img {
    border-radius: 130px;
    max-width: 120px;
    margin: 15px 0 0 20px;
  }

  .titlebackground {
    background: #f8f9fa;
    position: absolute;
    filter: blur(50px);
    width: 100%;
    height: 160px;
  }

  .titlebackgroundText {
    background: #f8f9fa;
    position: absolute;
    filter: blur(120px);
    width: 100%;
    left: 0%;
    top: 0;
    height: 100%;
    display: none;
  }
  .AboutImage{
    width:100%;
  } 

  .infoImage {
    width:100% !important;
  }

  .contentUpperMiddlePhoto {
    margin: 0px;
  }

  .contentLowerMiddlePhoto {
    margin: 0px;
  }
}

@media only screen and (max-width: 576px) {
  .topSpacer {
    background: #ffffffc4;
    min-height: 500px;
    margin-top: 175px;
}
.headerTitle {
  color: #ffffff;
  /* vertical-align: 50%; */
  position: relative;
  margin-top: 50px;
  text-align: center;
}

.headerTitle h2 {
  color: #000000;
  font-size: 40px;
  font-weight: bolder;
}

.AboutMain > h2 {
  position: absolute;
  top: -20px;
  left: 30%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.ShopMain > h2 {
  position: absolute;
  top: -47px;
  left: 25%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.fittedsaddleMain >  h2 {
  position: absolute;
  top: -25px;
  left: 20%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.otherServicesMain >  h2 {
  position: absolute;
  top: -25px;
  left: 20%;
  color: #000000;
  font-size: 40px;
  font-family:"Edwardian Script ITC V1" !important;
  text-shadow: 2px 2px #f8f9fa6b;
}

.pagebg {
  background: #ffffffc4;
  margin: 30px 0 25px;
  border-radius: 20px;
  color: #000000;
}
  .logolarge {
    display: none;
  }

  .logo img {
    border-radius: 130px;
    max-width: 120px;
    margin: 15px 0 0 20px;
  }

  .titlebackground {
    background: #f8f9fa;
    position: absolute;
    filter: blur(50px);
    width: 100%;
    height: 160px;
  }

  .titlebackgroundText {
    background: #f8f9fa;
    position: absolute;
    filter: blur(120px);
    width: 100%;
    left: 0%;
    top: 0;
    height: 100%;
    display: none;
  }
}

.fittedsaddleImage{
  width: 100%
}

.otherServicesImage{
  width: 100%
}

.card-deck .card {
  
  margin: 15px !important;
}
.SaddleGrid {
  border: dotted;
}

.SaddleGridTextArea {
  width: 100%;
}

.SaddleGridCol  {
  overflow-wrap: break-word;
}


img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px;   /* 50% */
  }
}

.shopimage {
  width: 100%;
}

.card-header {
  background-color: #ffffffc4 !important;
  margin: 5px;
}

.logofooter {
  width: 70px;
  margin: 15px;
}

.FooterMain{
  font-family: sans-serif;
  font-size: 20px;
}

.image-gallery  {
  width: 100%;
  image-orientation: from-image;
}

.NewsTextArea {
  height: 100%;
}

.round {
  border-radius: 20px;
}

.second-toolbar {
  display: none;
}

.infoContents {
  margin: 20px;
}

.latestnews {
  margin: 0 20px;
}

